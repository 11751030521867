import {
  PropertyImageViewModel,
  PropertyViewModel,
} from '@/services/modules/property'
import { Location } from 'vue-router'
import { normalizePath } from './misc'

export function getPropertyRouteTo(property: PropertyViewModel): Location {
  return {
    name: 'Property',
    params: {
      propertyKey: property.propertyKey.toString(),
      title: normalizePath(property.title),
    },
  }
}

export function getPropertyPhotoTourRouteTo(
  propertyImage?: PropertyImageViewModel
): Location {
  return {
    name: 'PropertyPhotoTour',
    query: {
      photoId: propertyImage?.imageKey.toString(),
    },
  }
}

export function getPropertyPhotoSlideShowRouteTo(
  propertyImage: PropertyImageViewModel
): Location {
  return {
    name: 'PropertyPhotoTour',
    params: {
      imageKey: propertyImage.imageKey.toString(),
    },
  }
}

export function getCompareListingsRouteTo(
  propertyList: PropertyViewModel[]
): Location {
  return {
    name: 'CompareListings',
    query: {
      key: propertyList.map((p) => p.propertyKey.toString()),
    },
  }
}

export function getPropertyGoogleMapsLatLng(property: PropertyViewModel) {
  return {
    lat: Number.parseFloat(property.addressViewModel.latitude),
    lng: Number.parseFloat(property.addressViewModel.longitude),
  }
}

export function getPropertyMetaTitle(property: PropertyViewModel) {
  return `${property.title} · ${property.typeViewModel.title} · ${property.categoryViewModel.title} · ${property.cityViewModel.name} ${property.stateViewModel.name}`
}

export const propertyOrderByConfig = {
  newestToOldest: {
    value: 'newest-to-oldest',
    categories: ['sales', 'rentals'],
  },
  oldestToNewest: {
    value: 'oldest-to-newest',
    categories: ['sales', 'rentals'],
  },
  priceHighToLow: {
    value: 'price-high-to-low',
    categories: ['sales'],
  },
  priceLowToHigh: {
    value: 'price-low-to-high',
    categories: ['sales'],
  },
  pricePerNightHighToLow: {
    value: 'price-per-night-high-to-low',
    categories: ['rentals'],
  },
  pricePerNightLowToHigh: {
    value: 'price-per-night-low-to-high',
    categories: ['rentals'],
  },
  pricePerMonthHighToLow: {
    value: 'price-per-month-high-to-low',
    categories: ['rentals'],
  },
  pricePerMonthLowToHigh: {
    value: 'price-per-month-low-to-high',
    categories: ['rentals'],
  },
}
