











































































































































































































































































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import { auth } from '@/composition/auth'
import { breakpointsRecords } from '@/design/system/helpers'
import { title } from '@/app.config.json'
import { currentRoute } from '@/router'
import i18n from '@/setup/i18n'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'LayoutAdmin',

  components: {
    BrandLogo,
  },

  setup() {
    const currentRouteTitle = computed(
      () =>
        (currentRoute.value &&
        currentRoute.value.meta &&
        currentRoute.value.meta.i18n
          ? i18n.t(currentRoute.value.meta.i18n)
          : '') as string
    )
    const currentYear = computed(() => new Date().getUTCFullYear())

    const isMenuOpen = ref(window.innerHeight > breakpointsRecords.md)

    return {
      currentUser: auth.currentUser,
      currentRouteTitle,
      currentYear,
      isMenuOpen,
      title,
    }
  },
})
