































































































import { compareListingStore } from '@/composition/compareListingStore'
import CompareListingsWidgetProperty from '@/components/CompareListingsWidgetProperty.vue'
import i18n from '@/setup/i18n'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { currentRoute } from '@/router'
import { getCompareListingsRouteTo } from '@/utils/property'

export default defineComponent({
  name: 'CompareListingsWidget',

  components: {
    CompareListingsWidgetProperty,
  },

  setup() {
    const isOpen = ref(false)

    // If the current view is not Compare Listings,
    // toggle the widget open state when the 'compare listings' list changes
    watch(
      () => compareListingStore.list.value,
      (newValue) => {
        isOpen.value =
          newValue.length > 0 && currentRoute.value?.name !== 'CompareListings'
      }
    )

    // If the current route changes,
    // hide the widget
    watch(
      () => currentRoute.value,
      () => {
        isOpen.value = false
      }
    )

    const ariaLabel = computed(() => i18n.t('routes.compareListings') as string)

    const routeTo = computed(() =>
      getCompareListingsRouteTo(compareListingStore.list.value)
    )

    return {
      isOpen,
      ariaLabel,
      list: compareListingStore.list,
      removeAll: compareListingStore.removeAll,
      routeTo,
    }
  },
})
