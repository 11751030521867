import { PropertyViewModel } from '@/services/modules/property'
import appStorage from '@/utils/appStorage'
import { Ref, ref, watch } from '@vue/composition-api'

export const compareListingStore = useCompareListingStore<PropertyViewModel>({
  localStorageKey: 'compare-listings',
  trackBy: 'propertyKey',
  onBeforeAdd: (property, list) => {
    if (
      list.some(
        (item) =>
          item.categoryViewModel.categoryId !==
          property.categoryViewModel.categoryId
      )
    ) {
      compareListingStore.removeAll()
    }
  },
})

export function useCompareListingStore<T>({
  localStorageKey,
  trackBy,
  onBeforeAdd,
  onAdd,
  onBeforeAddAll,
  onAddAll,
  onBeforeRemove,
  onRemove,
  onBeforeRemoveAll,
  onRemoveAll,
}: CompareListingStoreConfig<T>) {
  const maxListSize = 4
  const appStorageKey = appStorage.createNamespacedKey(localStorageKey)
  const localStoredList = appStorage.getObjectItem<T[]>(appStorageKey)
  const list = ref(
    localStoredList
      ? localStoredList.splice(
          Math.max(localStoredList.length - maxListSize),
          0
        )
      : []
  ) as Ref<T[]>

  watch(
    () => list.value,
    (newValue) => {
      appStorage.setObjectItem(appStorageKey, newValue)
    }
  )

  function add(item: T) {
    onBeforeAdd && onBeforeAdd(item, list.value)
    remove(item)

    if (list.value.length === maxListSize) {
      list.value.shift()
    }

    list.value.push(item)
    onAdd && onAdd(item, list.value)
  }

  function addAll(items: T[]) {
    onBeforeAddAll && onBeforeAddAll(items, list.value)
    for (const item of items) {
      add(item)
    }
    onAddAll && onAddAll(items, list.value)
  }

  function remove(item: T) {
    onBeforeRemove && onBeforeRemove(item, list.value)
    const index = trackBy
      ? list.value.findIndex((listItem) => listItem[trackBy] === item[trackBy])
      : list.value.indexOf(item)
    if (index === -1) return
    list.value.splice(index, 1)
    onRemove && onRemove(item, index, list.value)
  }

  function removeAll() {
    onBeforeRemoveAll && onBeforeRemoveAll(list.value)
    list.value = []
    onRemoveAll && onRemoveAll(list.value)
  }

  return {
    list,
    add,
    addAll,
    remove,
    removeAll,
  }
}

export interface CompareListingStoreConfig<T> {
  localStorageKey: string
  trackBy?: keyof T
  onBeforeAdd?: (item: T, currentList: T[]) => void
  onAdd?: (item: T, currentList: T[]) => void
  onBeforeAddAll?: (list: T[], currentList: T[]) => void
  onAddAll?: (list: T[], currentList: T[]) => void
  onBeforeRemove?: (item: T, currentList: T[]) => void
  onRemove?: (item: T, index: number, currentList: T[]) => void
  onBeforeRemoveAll?: (currentList: T[]) => void
  onRemoveAll?: (currentList: T[]) => void
}
