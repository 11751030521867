var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"flex space-x-4"},_vm._l((_vm.links),function(link,index){return _c('BaseLink',{key:link.locale,class:[
      'text-2xl',
      _vm.currentLocale === link.locale
        ? 'pointer-events-none'
        : 'cursor-pointer',
      { 'pr-4 border-r-2 border-current': index !== _vm.links.length - 1 } ],on:{"click":function($event){return _vm.loadLanguageAsync(link.locale)}}},[_c('BaseIcon',{class:[
        'hover:filter-none',
        { 'filter grayscale': _vm.currentLocale !== link.locale } ],attrs:{"name":link.icon,"source":"custom"}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }