

























































import appConfig from '@/app.config.json'
import Vue from 'vue'

export default Vue.extend({
  name: 'PwaNewContent',

  data() {
    return {
      appTitle: appConfig.title,
    }
  },

  computed: {
    rootEl(): HTMLElement {
      return this.$root.$el as HTMLElement
    },
  },

  methods: {
    changeNewContentStatus(): void {
      this.rootEl.dataset.newContent = 'false'
    },

    loadNewContent(): void {
      this.changeNewContentStatus()
      window.location.reload()
    },

    close(): void {
      this.changeNewContentStatus()
    },
  },
})
