






import LoadingSpinner from '@/components/loader/LoadingSpinner.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Loading',

  components: {
    LoadingSpinner,
  },
})
