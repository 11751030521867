





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseTextarea',

  inheritAttrs: false,

  model: {
    event: 'update',
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    function onInput(newValue: string) {
      context.emit('update', newValue)
    }

    return {
      onInput,
    }
  },
})
