














import Vue from 'vue'

export enum LoadingSpinnerDirection {
  Normal = 'normal',
  Reverse = 'reverse',
  Alternate = 'alternate',
  AlternateReverse = 'alternate-reverse',
}

export default Vue.extend({
  name: 'LoadingSpinner',

  props: {
    direction: {
      type: String,
      default: LoadingSpinnerDirection.Normal,
      validator: (value: string) =>
        Object.values(LoadingSpinnerDirection).includes(
          value as LoadingSpinnerDirection
        ),
    },

    size: {
      type: [String, Number],
      default: 50,
    },

    depth: {
      type: [String, Number],
      default: 4,
    },

    speed: {
      type: [String, Number],
      default: 2,
    },

    shapeSpeed: {
      type: [String, Number],
      default: 1.5,
    },

    color: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      defaultUnit: 'px',
      defaultSpeedUnit: 's',
    }
  },

  computed: {
    spinnerSize(): string {
      return this.getFormattedValue(this.size, this.defaultUnit)
    },

    spinnerDepth(): string {
      return this.getFormattedValue(this.depth, this.defaultUnit)
    },

    spinnerSpeed(): string {
      return this.getFormattedValue(this.speed, this.defaultSpeedUnit)
    },

    spinnerShapeSpeed(): string {
      return this.getFormattedValue(this.shapeSpeed, this.defaultSpeedUnit)
    },

    spinnerStyle(): Record<string, string | undefined> {
      return {
        width: this.spinnerSize,
        height: this.spinnerSize,
        color: this.color || undefined,
        animationDuration: this.spinnerSpeed,
        animationDirection: this.direction,
      }
    },

    spinnerShapeStyle(): Record<string, string> {
      return {
        animationDuration: this.spinnerShapeSpeed,
        animationDirection: this.direction,
      }
    },
  },

  methods: {
    getFormattedValue(value: string | number, defaultUnit: string): string {
      return typeof value === 'string' ? value : `${value}${defaultUnit}`
    },
  },
})
