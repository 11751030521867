


































































































































































































import SiteLanguageLinks from '@/components/SiteLanguageLinks.vue'
import { formatPhoneNumber } from '@/utils/phone'
import { SiteHeaderItemConfig } from '@/composition/siteHeader'
import { address, phone, email, socials, geolocation } from '@/app.config.json'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteFooter',

  components: {
    SiteLanguageLinks,
  },

  props: {
    siteMapItems: {
      type: Array as PropType<SiteHeaderItemConfig[]>,
      required: true,
    },
  },

  setup() {
    const currentYear = computed(() => new Date().getUTCFullYear())

    const companyPosition = computed(() => ({
      lat: Number.parseFloat(geolocation.latitude),
      lng: Number.parseFloat(geolocation.longitude),
    }))

    const markerOptions = computed<google.maps.MarkerOptions>(() => ({
      position: companyPosition.value,
      icon: {
        path: 'M17.3608 3.1584C16.1344 1.728 13.8712 0.0216 10.0672 0H9.93758C6.12878 0.0216 3.86558 1.728 2.63918 3.1584C1.18867 4.86508 0.387176 7.02902 0.375977 9.2688C0.375977 16.7088 8.87438 23.4504 9.23438 23.7336C9.45413 23.9056 9.72582 23.9979 10.0049 23.9953C10.284 23.9927 10.5539 23.8953 10.7704 23.7192C11.3152 23.3016 19.624 16.6632 19.624 9.2688C19.6128 7.02902 18.8113 4.86508 17.3608 3.1584ZM9.99998 13.2144C9.27705 13.2144 8.57035 13 7.96926 12.5984C7.36816 12.1967 6.89967 11.6259 6.62301 10.958C6.34636 10.2901 6.27397 9.55515 6.41501 8.84611C6.55605 8.13707 6.90417 7.48577 7.41536 6.97458C7.92655 6.46339 8.57784 6.11527 9.28688 5.97423C9.99592 5.8332 10.7309 5.90558 11.3988 6.18224C12.0667 6.45889 12.6375 6.92738 13.0392 7.52848C13.4408 8.12957 13.6552 8.83627 13.6552 9.5592C13.6552 10.5286 13.2701 11.4583 12.5846 12.1438C11.8991 12.8293 10.9694 13.2144 9.99998 13.2144Z',
        scale: 1.5,
        fillColor: '#ff681d',
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#fff',
        anchor: { x: 10, y: 20 } as google.maps.Point,
      },
    }))

    return {
      currentYear,
      address,
      phone,
      formattedPhone: formatPhoneNumber(phone),
      email,
      socials,
      companyPosition,
      markerOptions,
    }
  },
})
