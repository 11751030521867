


























import { enUs, esMx } from '@/utils/language'
import { unitedStates, mexico } from '@/utils/country'
import i18n, { loadLanguageAsync } from '@/setup/i18n'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteLanguageLinks',

  setup() {
    const currentLocale = computed(() => i18n.locale)

    const links = [
      {
        locale: enUs.locale,
        icon: `flag-${unitedStates.abbr}`,
      },
      {
        locale: esMx.locale,
        icon: `flag-${mexico.abbr}`,
      },
    ]

    return {
      currentLocale,
      links,
      loadLanguageAsync,
    }
  },
})
