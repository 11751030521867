import { ref } from '@vue/composition-api'

export const scrollLock = useScrollLock()

export function useScrollLock() {
  const isLocked = ref(false)
  const body = document.querySelector('body')

  function enable() {
    if (!body || isLocked.value) return
    body.style.overflow = 'hidden'
    isLocked.value = true
  }

  function disable() {
    if (!body || !isLocked.value) return
    body.style.removeProperty('overflow')
    isLocked.value = false
  }

  return {
    isLocked,
    enable,
    disable,
  }
}
