























import { PropertyViewModel } from '@/services/modules/property'
import { getPropertyRouteTo } from '@/utils/property'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CompareListingsWidgetProperty',

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },
  },

  setup(props) {
    const image = computed(() => props.estateProperty.propertyImageList[0])
    const routeTo = computed(() => getPropertyRouteTo(props.estateProperty))

    return {
      image,
      routeTo,
    }
  },
})
