import { Location } from 'vue-router'

interface ModelCategoryTab {
  id: string
  title: string
  tabPanelId: string
}

export interface ModelCategoryTabPanel {
  id: string
  tabId: string
  models: ModelCategoryTabPanelModelItem[]
}

interface TabListConfig {
  tabs: ModelCategoryTab[]
  tabPanels: ModelCategoryTabPanel[]
}

export interface ModelCategoryTabPanelModelItem {
  id: string
  title: string
  funcionality: ModelItemFuncionality
  footage: ModelItemFootage
  location: ModelItemLocation
  images: ModelItemImages
}

/*** FUNCIONALITY ***/
export interface ModelItemFuncionality {
  floor: FuncionalityFloor
  roof: FuncionalityRoof
}

export interface FuncionalityFloor {
  bedrooms: string
  bathrooms: string
  closet: string
  kitchen: string
  livingRoom: string
  stayStudy: string
  dinningRoom: string
  laundry: string
  terrace: string
}

export interface FuncionalityRoof {
  bedrooms: string
  bathrooms: string
  areaOne: string
  areaTwo: string
  areaThree: string
  pool: string
}

/*** FOOTAGE ***/
export interface ModelItemFootage {
  floor: Footage
  roof: Footage
  total: string
}

export interface Footage {
  inside: string
  terrace: string
}

/*** LOCATION ***/
export interface ModelItemLocation {
  floor: Locations
  roof: Locations
}

export interface Locations {
  levels: string
}

/*** IMAGES ***/
export interface ModelItemImages {
  plan: Images[]
  view: Images[]
}

export interface Images {
  id: string
  imageSrc: string
  imageAlt: string
}

export function getModelsTabListConfig(): TabListConfig {
  const categories = {
    department: {
      models: 4,
      images: {
        plan: 1,
        view: 2,
      },
    },
    penthouse: {
      models: 4,
      images: {
        plan: 1,
        view: 2,
      },
    },
    masterPenthouse: {
      models: 4,
      images: {
        plan: 2,
        view: 2,
      },
    },
  }

  const categoriesValues = Object.entries(categories)

  const tabs: ModelCategoryTab[] = []
  const tabPanels: ModelCategoryTabPanel[] = []

  for (const [categoryName, { models, images }] of categoriesValues) {
    const tabPanelModels: ModelCategoryTabPanelModelItem[] = []

    for (let modelIndex = 0; modelIndex < models; modelIndex++) {
      const planImages: Images[] = []
      const viewImages: Images[] = []
      const costessaBaseI18nPath = `costessa.${categoryName}.models.${modelIndex}`
      const costessaFuncBaseI18nPath = `${costessaBaseI18nPath}.funcionality`

      // TODO: GET PLAN IMAGES
      for (let imageIndex = 0; imageIndex < images.plan; imageIndex++) {
        const imageBaseI18nPath = `${costessaBaseI18nPath}.images.plan.${imageIndex}`

        planImages.push({
          id: `${categoryName}-${modelIndex}-${imageIndex}`,
          imageSrc: `@/assets/images/costessa/models/${categoryName}/${modelIndex}/${imageIndex}-plan.png`,
          imageAlt: `${imageBaseI18nPath}.imageAlt`,
        })
      }

      // TODO: GET VIEW IMAGES
      for (let imageIndex = 0; imageIndex < images.view; imageIndex++) {
        const imageBaseI18nPath = `${costessaBaseI18nPath}.images.view.${imageIndex}`

        viewImages.push({
          id: `${categoryName}-${modelIndex}-${imageIndex}`,
          imageSrc: `@/assets/images/costessa/models/${categoryName}/${modelIndex}/${imageIndex}-view.png`,
          imageAlt: `${imageBaseI18nPath}.imageAlt`,
        })
      }

      tabPanelModels.push({
        id: `${costessaBaseI18nPath}.id`,
        title: `${costessaBaseI18nPath}.title`,
        funcionality: {
          floor: {
            bedrooms: `${costessaFuncBaseI18nPath}.floor.bedrooms`,
            bathrooms: `${costessaFuncBaseI18nPath}.floor.bathrooms`,
            closet: `${costessaFuncBaseI18nPath}.floor.closet`,
            kitchen: `${costessaFuncBaseI18nPath}.floor.kitchen`,
            livingRoom: `${costessaFuncBaseI18nPath}.floor.livingRoom`,
            stayStudy: `${costessaFuncBaseI18nPath}.floor.stayStudy`,
            dinningRoom: `${costessaFuncBaseI18nPath}.floor.dinningRoom`,
            laundry: `${costessaFuncBaseI18nPath}.floor.laundry`,
            terrace: `${costessaFuncBaseI18nPath}.floor.terrace`,
          },
          roof: {
            bedrooms: `${costessaFuncBaseI18nPath}.roof.bedrooms`,
            bathrooms: `${costessaFuncBaseI18nPath}.roof.bathrooms`,
            areaOne: `${costessaFuncBaseI18nPath}.roof.areaOne`,
            areaTwo: `${costessaFuncBaseI18nPath}.roof.areaTwo`,
            areaThree: `${costessaFuncBaseI18nPath}.roof.areaThree`,
            pool: `${costessaFuncBaseI18nPath}.roof.pool`,
          },
        },
        footage: {
          floor: {
            inside: `${costessaBaseI18nPath}.footage.floor.inside`,
            terrace: `${costessaBaseI18nPath}.footage.floor.terrace`,
          },
          roof: {
            inside: `${costessaBaseI18nPath}.footage.roof.inside`,
            terrace: `${costessaBaseI18nPath}.footage.roof.terrace`,
          },
          total: `${costessaBaseI18nPath}.footage.total`,
        },
        location: {
          floor: {
            levels: `${costessaBaseI18nPath}.location.floor.levels`,
          },
          roof: {
            levels: `${costessaBaseI18nPath}.location.roof.levels`,
          },
        },
        images: {
          plan: planImages,
          view: viewImages,
        },
      })
    }

    const tabId = `${categoryName}-tab`
    const tabPanelId = `${categoryName}-tabpanel`

    tabs.push({
      id: tabId,
      title: `costessa.${categoryName}.title`,
      tabPanelId: tabPanelId,
    })

    tabPanels.push({
      id: tabPanelId,
      tabId: tabId,
      models: tabPanelModels,
    })
  }

  return {
    tabs,
    tabPanels,
  }
}

export interface CostessaImageViewModel {
  imageId: string
  imageKey: number
  name: string
  description: string | null
  path: string
}

const galleryPath = 'https://api.mrcodigo.com/images/vive/costessa'

export const galleryImageList: CostessaImageViewModel[] = [
  {
    imageId: 'img-1',
    imageKey: 1,
    name: 'camastros.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-2',
    imageKey: 2,
    name: 'torre3.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-3',
    imageKey: 3,
    name: 'torre2.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-4',
    imageKey: 4,
    name: 'torre1.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-5',
    imageKey: 5,
    name: 'salon_eventos.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-6',
    imageKey: 6,
    name: 'restaurante.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-7',
    imageKey: 7,
    name: 'pool2.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-8',
    imageKey: 8,
    name: 'alberca2.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-9',
    imageKey: 9,
    name: 'MPHD_game_room.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-10',
    imageKey: 10,
    name: 'MPHD_cocina.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-11',
    imageKey: 11,
    name: 'MPHC_vista.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-12',
    imageKey: 12,
    name: 'MPHC_terraza.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-13',
    imageKey: 13,
    name: 'MPHC_sala_comedor.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-14',
    imageKey: 14,
    name: 'MPHC_recamara.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-15',
    imageKey: 15,
    name: 'MPHC_alberca.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-16',
    imageKey: 16,
    name: 'MPHB_vista.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-17',
    imageKey: 17,
    name: 'MPHB_terraza.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-18',
    imageKey: 18,
    name: 'MPHB_sala_comedor.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-19',
    imageKey: 19,
    name: 'MPHB_game_room.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-20',
    imageKey: 20,
    name: 'MPHA_vista.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-21',
    imageKey: 21,
    name: 'MPHA_terraza.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-22',
    imageKey: 22,
    name: 'MPHA_sala_comedor.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-23',
    imageKey: 23,
    name: 'MPHA_cocina.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-24',
    imageKey: 24,
    name: 'MPHA_alberca.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-25',
    imageKey: 25,
    name: 'M2_vista.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-26',
    imageKey: 26,
    name: 'M2_sala_comedor.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-27',
    imageKey: 27,
    name: 'M2_recamara.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-28',
    imageKey: 28,
    name: 'M1L_vista.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-29',
    imageKey: 29,
    name: 'M1L_sala_comedor.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-30',
    imageKey: 30,
    name: 'M1L_recamara.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-31',
    imageKey: 31,
    name: 'M1_vista.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-32',
    imageKey: 32,
    name: 'M1_sala_comedor2.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-33',
    imageKey: 33,
    name: 'M1_sala_comedor.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-34',
    imageKey: 34,
    name: 'M1_cocina.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-35',
    imageKey: 35,
    name: 'lobby_lounge2.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-36',
    imageKey: 36,
    name: 'lobby_lounge.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-37',
    imageKey: 37,
    name: 'gym.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-38',
    imageKey: 38,
    name: 'lobby1.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-39',
    imageKey: 39,
    name: 'cowork.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-40',
    imageKey: 40,
    name: 'vista_exterior.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-41',
    imageKey: 41,
    name: 'sala_interior.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-42',
    imageKey: 42,
    name: 'recamara_interior.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-43',
    imageKey: 43,
    name: 'edificio_exterior.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-44',
    imageKey: 44,
    name: 'cocina_interior.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-45',
    imageKey: 45,
    name: 'balcon_exterior.jpg',
    description: '',
    path: galleryPath,
  },
  {
    imageId: 'img-46',
    imageKey: 46,
    name: 'area_comun_interior.jpg',
    description: '',
    path: galleryPath,
  },
]

export function getCostessaRouteTo(): Location {
  return {
    name: 'Costessa',
  }
}

export function getCostessaPhotoTourRouteTo(
  modelImage?: CostessaImageViewModel
): Location {
  return {
    name: 'CostessaPhotoTour',
    query: {
      photoId: modelImage?.imageKey.toString(),
    },
  }
}

export function getCostessaPhotoSlideShowRouteTo(
  modelImage: CostessaImageViewModel
): Location {
  return {
    name: 'CostessaPhotoTour',
    params: {
      imageKey: modelImage.imageKey.toString(),
    },
  }
}

export const costessaMenu = [
  {
    id: 'home',
    i18nPath: 'routes.costessaMenu.home',
    toRoute: {
      name: 'Costessa',
      hash: '#home',
    },
  },
  {
    id: 'location',
    i18nPath: 'routes.costessaMenu.location',
    toRoute: {
      name: 'Costessa',
      hash: '#location',
    },
  },
  {
    id: 'amenities',
    i18nPath: 'routes.costessaMenu.amenities',
    toRoute: {
      name: 'Costessa',
      hash: '#amenities',
    },
  },
  {
    id: 'models',
    i18nPath: 'routes.costessaMenu.models',
    toRoute: {
      name: 'Costessa',
      hash: '#models',
    },
  },
  {
    id: 'payment-plans',
    i18nPath: 'routes.costessaMenu.paymentPlans',
    toRoute: {
      name: 'Costessa',
      hash: '#payment-plans',
    },
  },
  {
    id: 'gallery',
    i18nPath: 'routes.costessaMenu.gallery',
    toRoute: {
      name: 'Costessa',
      hash: '#gallery',
    },
  },
  {
    id: 'contact',
    i18nPath: 'routes.costessaMenu.contact',
    toRoute: {
      name: 'Costessa',
      hash: '#contact',
    },
  },
]
